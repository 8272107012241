import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import {
  getListings,
  getCategoryPreferenaces,
  updateCategoryPreference,
  getCategoryPreferenacesDropdown,
  setAviationModel,
  detailListing,
} from "../../../store/listings";
import CategoryPreferenacesModal from "../../../layouts/modals/categoryPreferencesModal/categoryPreferencesModal";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import { toast } from "react-toastify";
import { addAviationType } from "../../../store/aviation";
import { getModals, setCategoryModal, setConditionModal } from "../../../store/modal";
import PushCategories from "../../../layouts/modals/categoryPreferencesModal/pushCategories";
import {
  getListingProductDetail,
  getMfrAndModel,
  mfrAndModelReceived,
  productCondition,
  productConditionReceived,
  updateMfrAndModel,
  updateProductCondition,
} from "../../../store/listingProduct";
import ProductConditionModal from "../../../layouts/modals/productConditionModal";
import { getAviationModel, getManufacturers, getMisc, getModel } from "../../../store/misc";
import HTMLReactParser from "html-react-parser";

const CategoryPreferenaces = (props) => {
  // const [categoryToggle, setCategoryToggle] = useState(false);
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [categoryDropdown, setCategoryDropdown] = useState(null);
  const [select, setSelect] = useState();
  const [checkList, setCheckList] = useState([]);
  const [subCat, setSubCat] = useState();
  const [airCraftType, setAirCraftType] = useState("");
  const [primaryCategory, setPrimaryCategory] = useState([]);
  const [aviationType, setAviationType] = useState({ name: "Please Select", value: "0" });
  const [showPush, setShowPush] = useState(false);

  const [errors, setErrors] = useState({});

  const validate = () => {
    const error = { ...errors };
    if (!primaryCategory?.firstLevel?.name && !primaryCategory?.firstLevel?.active) {
      error.firstLevel = "Category is Required";
    } else {
      delete error.firstLevel;
    }

    if (
      primaryCategory?.firstLevel?.name &&
      !primaryCategory?.secondLevel?.name &&
      !primaryCategory?.secondLevel?.active
    ) {
      error.secondLevel = "Category Level 2 is Required";
    } else {
      delete error.secondLevel;
    }

    if (
      (primaryCategory?.secondLevel?.name == "Aircraft Parts" ||
        primaryCategory?.secondLevel?.name == "Complete Aircrafts" ||
        primaryCategory?.secondLevel?.subCategories?.length > 0) &&
      !primaryCategory?.thirdLevel?.active
    ) {
      error.thirdLevel = "Category Level 3 is Required";
    } else {
      delete error.thirdLevel;
    }
    const isEmpty = Object.values(error).every((x) => x === null || x === "");
    if (!isEmpty) {
      setErrors(error);
      return false;
    } else {
      setErrors(error);
      return true;
    }
  };

  const dispatch = useDispatch();
  const showConditionModal = props?.conditionModal;
  const toggleConditionModal = () => {
    props?.setShowConditionModal(!showConditionModal);
  };

  const [scrapValue, setScrapValue] = useState(0);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [editorData, setEditorData] = useState("");
  const [loadingCondition, setLoadingCondition] = useState(false);
  const [savedData, setSavedData] = useState("");
  const [conditions, setConditions] = useState({ name: "", value: "" });
  const [otherData, setOtherData] = useState("");

  const [loadingManufacturer, setLoadingManufacturer] = useState(false);
  const [mfrValue, setMfrValue] = useState({ manufacturer: "" });

  const conditionOptions = [
    { name: "New", value: "New" },
    { name: "New Surplus", value: "New Surplus" },
    { name: "Used", value: "Used" },
    { name: "Damaged", value: "Damaged" },
    { name: "Scrap", value: "Scrap" },

    { name: "Factory New", value: "Factory New" },
    { name: "Overhauled", value: "Overhauled" },
    { name: "Serviceable", value: "Serviceable" },
    { name: "As Removed", value: "As Removed" },
    { name: "Repairable", value: "Repairable" },
    { name: "Repaired", value: "Repaired" },
    { name: "Inspected", value: "Inspected" },
    { name: "Core", value: "Core" },

    { name: "Beyond Economical Repair", value: "Beyond Economical Repair" },
    { name: "Rebuilt", value: "Rebuilt" },
    { name: "Modified", value: "Modified" },

    { name: "Tested", value: "Tested" },
    { name: "Unserviceable", value: "Unserviceable" },

    { name: "Disposal", value: "Disposal" },
    { name: "Disposal/Free", value: "Disposal/Free" },
    { name: "Unused", value: "Unused" },
    { name: "Others", value: "Others" },
  ];

  const condition =
    props.getListingProductDetail &&
    props.getListingProductDetail.condition &&
    props.getListingProductDetail.condition.detail;

  // const handleSubmit = () => {
  //   setLoadingCondition(true);
  //   props.refStart();
  //   const id = props.auctionId;
  //   const data = {
  //     condition: editorData,
  //     scrap: scrapValue,
  //     conditionType: conditions?.name ? (conditions?.name == "Others" ? otherData : conditions?.name) : "",
  //   };
  //   props.updateProductCondition(id, data, (res) => {
  //     if (res.status === 200) {
  //       const detail = {
  //         condition: editorData,
  //         scrap: scrapValue ? true : false,
  //         conditionType: conditions?.name ? (conditions?.name == "Others" ? otherData : conditions?.name) : "",
  //       };
  //       setLoadingCondition(false);
  //       props.refStop();
  //       props.productConditionReceived({ detail });
  //       toggleConditionModal();
  //       toast(<AlertSuccess message="Product Condition Updated" />);
  //     } else {
  //       setLoadingCondition(false);
  //       props.refStop();
  //       toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
  //     }
  //   });
  // };

  const categoryToggle = props?.categoryModal;

  const categoryData = (props.getListings && props.getListings.category && props.getListings.category.data) || {};

  // whole category data for edit
  const categoryDropDownData =
    (props.getListings && props.getListings.categoryDrpDown && props.getListings.categoryDrpDown.data?.categories) ||
    [];

  useEffect(() => {
    if (showConditionModal) {
      if (condition?.condition) {
        setSavedData(condition?.condition);
      }

      setScrapValue(condition?.scrap ? 1 : 0);
      if (condition?.conditionType?.length > 0) {
        const a = conditionOptions?.filter((item) => item?.name == condition?.conditionType);
        if (a?.length > 0) {
          setConditions({ name: condition?.conditionType, value: condition?.conditionType });
        } else {
          setConditions({ name: "Others", value: "Others" });
          setOtherData(condition?.conditionType);
        }
      } else {
        setConditions(null);
      }
      setMfrValue({ manufacturer: { name: categoryData?.m_description, value: categoryData?.m_mfrId } });
    }
  }, [showConditionModal]);

  const togglePush = () => {
    setShowPush(!showPush);
  };

  useEffect(() => {
    props.getManufacturers((res) => {
      if (res.status === 200) {
        setLoadingManufacturer(false);
      } else {
        setLoadingManufacturer(false);
      }
    });
  }, []);

  const aviationModelData = props?.aviationModel?.data || [];

  const aviationModel = props?.getListings?.category?.data?.aviation_model_id;

  useEffect(() => {
    if (aviationModel && aviationModelData?.length > 0) {
      const b = aviationModelData?.filter((hd) => hd?.aviationModelID == aviationModel)?.[0] || [];
      if (b) {
        setAviationType({ name: b?.description, value: b?.aviationModelID });
        props?.setAviationModel(b?.description);
      }
    }
  }, [aviationModel, aviationModelData]);

  useEffect(() => {
    if (showConditionModal && categoryDropDownData) {
      const data =
        categoryDropDownData &&
        categoryDropDownData.length > 0 &&
        categoryDropDownData.map((item) => ({
          name: item.description,
          value: item.category_id,
        }));
      setCategoryDropdown(data);

      const sub =
        categoryDropDownData &&
        categoryDropDownData.length > 0 &&
        categoryDropDownData.map((item) => ({
          subCategories: item.subCategories,
          value: item.category_id,
        }));
      setSubCat(sub);
    }
  }, [showConditionModal, categoryDropDownData]);
  useEffect(() => {
    if (props?.auctionId) {
      setLoadingCategory(true);
      props?.getCategoryPreferenacesDropdown(props.auctionId, (res) => {
        if (res && res.status == 200) {
          setLoadingCategory(false);
        }
      });
    }
  }, [props?.auctionId]);

  useEffect(() => {
    if (props?.aviationType) {
      setAirCraftType(props.aviationType);
    }
  }, [props?.aviationType]);

  const toggleCategoryPreferenaces = () => {
    // setCategoryToggle(!categoryToggle);
    dispatch(setCategoryModal(!categoryToggle));
  };

  // const handleSubmitMfr = () => {
  //   props.refStart();
  //   const data = {
  //     mfr_id: mfrValue.manufacturer.value
  //       ? mfrValue.manufacturer.value === 1305
  //         ? 0
  //         : mfrValue.manufacturer.value
  //       : 0,
  //     mfr: mfrValue.manufacturer.name,
  //   };
  //   props.updateMfrAndModel(props.auctionId, data, (res) => {
  //     if (res.status === 200) {
  //       props.refStop();
  //       toast(<AlertSuccess message="Information Saved" />);
  //     } else {
  //       props.refStop();
  //       toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
  //     }
  //   });
  // };

  const handleSubmitMain = () => {
    // handleSubmitMfr();
    if (validate()) {
      setLoadingCategory(true);
      props.refStart();
      const id = props.auctionId;
      const data = {
        condition: editorData,
        scrap: scrapValue,
        conditionType: conditions?.name ? (conditions?.name == "Others" ? otherData : conditions?.name) : "",
        category_id: select.value,
        aviation_model_id: aviationType.value,
        category_id_push: checkList.toString(),
        mfr_id: mfrValue.manufacturer.value
          ? mfrValue.manufacturer.value === 1305
            ? 0
            : mfrValue.manufacturer.value
          : 0,
        // mfr: mfrValue.manufacturer.name,
      };
      if (
        primaryCategory?.secondLevel?.name == "Aircraft Parts" ||
        primaryCategory?.secondLevel?.name == "Complete Aircrafts"
      ) {
        data.aviation_model_id = aviationType?.value ? aviationType?.value : 0;
      }
      props.updateCategoryPreference(id, data, (res) => {
        if (res.status === 200) {
          props.getCategoryPreferenaces(props.auctionId, (res) => {
            if (res.status === 200) {
              setLoadingCategory(false);
              props?.productCondition(id, (res) => {});
              props?.getMfrAndModel(id, (res) => {});
              props?.detailListing(id, (res) => {});
              props.refStop();
              if (showConditionModal) {
                toggleConditionModal();
                setOtherData("");
                toast(<AlertSuccess message="Information Saved" />);
              } else if (showPush) {
                togglePush();
                toast(<AlertSuccess message="Information Saved" />);
              }
            } else {
              setLoadingCategory(false);
              props.refStop();
              toast(
                <AlertError
                  message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"}
                />,
              );
            }
          });
        } else {
          setLoadingCategory(false);
          props.refStop();
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    }
  };

  useEffect(() => {
    if (props.getListings && props.getListings.category && props.getListings.category.data) {
      const a = {};
      const treeLength = props.getListings.category.data?.categoryTree?.length;
      const treeData = props.getListings.category.data?.categoryTree;
      if (treeLength == 1) {
        setSelect({ name: treeData[0]?.description, value: treeData[0]?.category_id });
        setPrimaryCategory({ firstLevel: { active: true } });
      } else if (treeLength == 2) {
        const item = categoryDropDownData?.filter((oi) => oi?.category_id == treeData[1]?.category_id)[0]
          ?.subCategories;
        setSelect({ name: treeData[0]?.description, value: treeData[0]?.category_id });
        setPrimaryCategory({
          firstLevel: { name: treeData[1]?.description, value: treeData[1]?.category_id, subCategories: item },
          secondLevel: { active: true },
        });
      } else {
        const item = categoryDropDownData?.filter((oi) => oi?.category_id == treeData[2]?.category_id)[0]
          ?.subCategories;
        const item1 = item?.filter((oi) => oi?.category_id == treeData[1]?.category_id)[0]?.subCategories;
        setSelect({ name: treeData[0]?.description, value: treeData[0]?.category_id });
        setPrimaryCategory({
          firstLevel: { name: treeData[2]?.description, value: treeData[2]?.category_id, subCategories: item },
          secondLevel: { name: treeData[1]?.description, value: treeData[1]?.category_id, subCategories: item1 },
          thirdLevel: { active: true },
        });
      }
    }

    const a =
      categoryData && categoryData.category && categoryData.category.length > 0
        ? categoryData.category.map((item) => item.category_id)
        : [];
    setCheckList(a);
  }, [showConditionModal, showPush]);

  const handleAircraftTypeChanger = (data) => {
    props?.refStart();
    const id = props.auctionId;
    props?.addAviationType(id, { aviationType: data }, (res) => {
      if (res && res.status == 200) {
        setAirCraftType(data);
        props?.refStop();
      } else {
        props?.refStop();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  useEffect(() => {
    props?.getAviationModel((res) => {});
  }, []);

  const options =
    props.getMisc &&
    props.getMisc.manufacturers &&
    props.getMisc.manufacturers.data &&
    props.getMisc.manufacturers.data.length > 0 &&
    props.getMisc.manufacturers.data.map((item) => ({
      name: `${item.Description} ${item.ra_users ? `(${item.ra_users})` : ""}`,
      value: item.ID,
    }));

  return (
    <>
      <div class="details-page-widget-block" id={props.id}>
        <div class="page-widget-head d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <div
              class="page-widget-label text-capitalize cursor-pointer"
              onClick={() => {
                if (!props.loadingMain && !loadingCategory) {
                  toggleConditionModal();
                }
              }}
            >
              Category, Condition & Manufacturer
            </div>
          </div>

          {/* <div class="btn-block d-flex align-items-center">
            {props.loadingMain ? (
              <Skeleton />
            ) : (
              <a href="javascript:void(0)" class="btn btn-primary btn-sm" onClick={() => toggleCategoryPreferenaces()}>
                Edit
              </a>
            )}
          </div> */}
        </div>
        {props?.isAviationCategory && !props.loadingMain && (
          <div className="pdc-body" style={{ paddingTop: "0px" }}>
            {/* <h6>{props.loadingMain ? <Skeleton width="50%" /> : "Seller Motivation"}</h6> */}
            <div class="table-responsive">
              <table class="table align-middle product-table">
                <colgroup>
                  <col style={{ width: "36%" }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <td>
                      <span> Type </span>
                    </td>
                    <td>
                      <div class="td-radio-group">
                        <div class="form-check radio">
                          <input
                            class="form-check-input cursor-pointer"
                            type="radio"
                            name="flexRadioDefault231"
                            id="Aircrafts"
                            checked={airCraftType == "1"}
                            onClick={(e) => handleAircraftTypeChanger(1)}
                          />
                          <label class="form-check-label" for="Aircrafts">
                            Complete Aircrafts
                          </label>
                        </div>
                        <div class="form-check radio">
                          <input
                            class="form-check-input cursor-pointer"
                            type="radio"
                            name="flexRadioDefault2"
                            id="Equipments"
                            checked={airCraftType == "3"}
                            onClick={(e) => handleAircraftTypeChanger(3)}
                          />
                          <label class="form-check-label" for="Equipments">
                            Aviaton Parts
                          </label>
                        </div>
                        <div class="form-check radio">
                          <input
                            class="form-check-input cursor-pointer"
                            type="radio"
                            name="flexRadioDefault2"
                            id="Equipments"
                            checked={airCraftType == "2"}
                            onClick={(e) => handleAircraftTypeChanger(2)}
                          />
                          <label class="form-check-label" for="Equipments">
                            Airport Ground Equipments
                          </label>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        <div class="page-widget-body mt-3">
          <div class="table-responsive">
            <table class="table align-middle product-table">
              <colgroup>
                <col style={{ width: "36%" }} />
                <col />
              </colgroup>
              <tbody>
                {props.loadingMain || loadingCategory ? (
                  <>
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr>
                      <td>Category</td>
                      <td>
                        {categoryData?.categoryTree?.length > 0
                          ? []
                              .concat(categoryData?.categoryTree)
                              .reverse()
                              ?.map((jd) => jd?.description)
                              .join(" > ")
                          : "N/A"}
                      </td>
                    </tr>
                    {categoryData?.categoryTree?.length > 0 &&
                    (categoryData?.categoryTree?.some((val) => val.description === "Aircraft Parts") ||
                      categoryData?.categoryTree?.some((val) => val.description === "Complete Aircrafts")) ? (
                      <tr>
                        <td>Aviation Model</td>
                        <td>
                          {aviationType?.name
                            ? aviationType?.name === "Please Select"
                              ? "N/A"
                              : aviationType?.name
                            : "N/A"}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                    <tr>
                      <td>Manufacturer</td>
                      <td>{categoryData && categoryData?.m_description ? categoryData?.m_description : "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Condition</td>
                      <td>{condition && condition?.conditionType ? condition?.conditionType : "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Description</td>
                      <td className="ck-content">
                        {condition && condition?.condition ? HTMLReactParser(condition.condition) : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td>Scrap</td>
                      <td>{condition && condition?.scrap ? "Yes" : "No"}</td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
          <div
            class="page-widget-label text-capitalize mb-3 cursor-pointer"
            onClick={() => {
              if (!props.loadingMain && !loadingCategory) {
                // togglePush();
                toggleConditionModal();
              }
            }}
          >
            {`Push Categories (${categoryData?.category?.length ? categoryData?.category?.length : "0"}`})
          </div>
          {!props?.loadingMain && categoryData?.category?.length ? (
            <div class="table-responsive">
              <table class="table align-middle product-table">
                <colgroup>
                  <col style={{ width: "36%" }} />
                  <col />
                </colgroup>
                <tbody>
                  {props.loadingMain || loadingCategory ? (
                    <>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {categoryData && categoryData.category && categoryData.category.length > 0
                        ? categoryData.category.map((val) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    <ul>
                                      {val.subCategories?.length ? (
                                        val.subCategories
                                          ?.slice()
                                          ?.reverse()
                                          ?.map((val) => <li>{`${val}`}</li>)
                                      ) : (
                                        <li>--</li>
                                      )}
                                    </ul>
                                  </td>
                                  <td> {val.description}</td>
                                </tr>
                              </>
                            );
                          })
                        : ""}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            ""
          )}
        </div>
        <PushCategories
          show={showPush}
          onHide={togglePush}
          loadingCategory={loadingCategory}
          categoryDropdown={categoryDropdown}
          categoryDropDownData={categoryDropDownData}
          setCategoryDropdown={setCategoryDropdown}
          checkList={checkList}
          setCheckList={setCheckList}
          select={select}
          setSelect={setSelect}
          subCat={subCat}
          setSubCat={setSubCat}
          handleSubmit={handleSubmitMain}
          categoryData={categoryData}
        />
        {/* <CategoryPreferenacesModal
          show={categoryToggle}
          onHide={toggleCategoryPreferenaces}
          loadingCategory={loadingCategory}
          categoryDropdown={categoryDropdown}
          categoryDropDownData={categoryDropDownData}
          setCategoryDropdown={setCategoryDropdown}
          checkList={checkList}
          setCheckList={setCheckList}
          select={select}
          setSelect={setSelect}
          subCat={subCat}
          setSubCat={setSubCat}
          handleSubmitMain={handleSubmitMain}
          categoryData={categoryData}
          primaryCategory={primaryCategory}
          setPrimaryCategory={setPrimaryCategory}
        /> */}
        <ProductConditionModal
          show={showConditionModal}
          onHide={toggleConditionModal}
          editorLoaded={editorLoaded}
          setEditorLoaded={setEditorLoaded}
          editorData={editorData}
          setEditorData={setEditorData}
          scrapValue={scrapValue}
          setScrapValue={setScrapValue}
          // handleSubmit={handleSubmit}
          loadingCondition={loadingCondition}
          savedData={savedData}
          conditionOptions={conditionOptions}
          condition={conditions}
          setCondition={setConditions}
          otherData={otherData}
          setOtherData={setOtherData}
          loadingCategory={loadingCategory}
          categoryDropdown={categoryDropdown}
          categoryDropDownData={categoryDropDownData}
          setCategoryDropdown={setCategoryDropdown}
          checkList={checkList}
          setCheckList={setCheckList}
          select={select}
          setSelect={setSelect}
          subCat={subCat}
          setSubCat={setSubCat}
          handleSubmit={handleSubmitMain}
          categoryData={categoryData}
          primaryCategory={primaryCategory}
          setPrimaryCategory={setPrimaryCategory}
          aviationModel={props?.aviationModel}
          aviationType={aviationType}
          setAviationType={setAviationType}
          options={options}
          setMfrValue={setMfrValue}
          mfrValue={mfrValue}
          loadingManufacturer={loadingManufacturer}
          airCraftType={airCraftType}
          errors={errors}
        />
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getCategoryPreferenaces: (params, callback) => dispatch(getCategoryPreferenaces(params, callback)),
  updateCategoryPreference: (params, data, callback) => dispatch(updateCategoryPreference(params, data, callback)),
  addAviationType: (id, data, callback) => dispatch(addAviationType(id, data, callback)),
  getCategoryPreferenacesDropdown: (params, callback) => dispatch(getCategoryPreferenacesDropdown(params, callback)),
  productCondition: (params, callback) => dispatch(productCondition(params, callback)),
  updateProductCondition: (params, data, callback) => dispatch(updateProductCondition(params, data, callback)),
  getAviationModel: (callback) => dispatch(getAviationModel(callback)),

  //global state for manipulating data
  productConditionReceived: (data) => dispatch(productConditionReceived(data)),
  setShowConditionModal: (data) => dispatch(setConditionModal(data)),
  setAviationModel: (data) => dispatch(setAviationModel(data)),
  getManufacturers: (callback) => dispatch(getManufacturers(callback)),
  getModel: (data, callback) => dispatch(getModel(data, callback)),
  getMfrAndModel: (data, callback) => dispatch(getMfrAndModel(data, callback)),
  updateMfrAndModel: (params, data, callback) => dispatch(updateMfrAndModel(params, data, callback)),
  mfrAndModelReceived: (data) => dispatch(mfrAndModelReceived(data)),
  detailListing: (id, callback) => dispatch(detailListing(id, callback)),
});

const mapStateToProps = (state) => ({
  getListings: getListings(state),
  categoryModal: getModals(state)?.categoryModal,
  getListingProductDetail: getListingProductDetail(state),
  conditionModal: getModals(state)?.conditionModal,
  aviationModel: getMisc(state)?.aviationModel,
  getMisc: getMisc(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CategoryPreferenaces));
