import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getDealProgress, getMisc, updateDealProgress } from "../../../store/misc";
import { getCategoryPreferenaces, getListings, updateCategoryPreference } from "../../../store/listings";
import moment from "moment";
import AlertError from "../../../common/alerts/alertError";
import { toast } from "react-toastify";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import CategoryPreferencesModal from "../../../layouts/modals/categoryPreferencesModal/categoryPreferencesModal";
import Skeleton from "react-loading-skeleton";
import { getModals, setConditionModal } from "../../../store/modal";
import { getProfile } from "../../../utils.js/localStorageServices";
import { getPendingTasks } from "../../../store/tasks";
import { getProductCount } from "../../../store/listingProduct";

const DealProgress = (props) => {
  const session = getProfile();
  const [categoryToggle, setCategoryToggle] = useState(false);
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [categoryDropdown, setCategoryDropdown] = useState(null);
  const [select, setSelect] = useState();
  const [checkList, setCheckList] = useState([]);
  const [subCat, setSubCat] = useState();

  const categoryData = (props.getListings && props.getListings.category && props.getListings.category.data) || {};

  // whole category data for edit
  const categoryDropDownData =
    (props.getListings && props.getListings.categoryDrpDown && props.getListings.categoryDrpDown.data?.categories) ||
    [];

  useEffect(() => {
    const data =
      categoryDropDownData &&
      categoryDropDownData.length > 0 &&
      categoryDropDownData.map((item) => ({
        name: item.description,
        value: item.category_id,
      }));
    setCategoryDropdown(data);

    const sub =
      categoryDropDownData &&
      categoryDropDownData.length > 0 &&
      categoryDropDownData.map((item) => ({
        subCategories: item.subCategories,
        value: item.category_id,
      }));
    setSubCat(sub);
  }, [categoryToggle]);

  const toggleCategoryPreferenaces = () => {
    setCategoryToggle(!categoryToggle);
  };

  const handleSubmit = () => {
    setLoadingCategory(true);
    props.refStart();
    const id = props.auctionId;
    const data = {
      category_id: select.value,
      category_id_push: checkList.toString(),
    };
    props.updateCategoryPreference(id, data, (res) => {
      if (res.status === 200) {
        props.getCategoryPreferenaces(props.auctionId, (res) => {
          if (res.status === 200) {
            setLoadingCategory(false);
            toggleCategoryPreferenaces();
            props.refStop();
            toast(<AlertSuccess message={"Information Saved"} />);
          } else {
            setLoadingCategory(false);
            props.refStop();
            toast(
              <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
            );
          }
        });
      } else {
        setLoadingCategory(false);
        props.refStop();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  useEffect(() => {
    if (props.getListings && props.getListings.category && props.getListings.category.data) {
      const a = {};
      a.name = categoryData && categoryData.cat_des ? categoryData.cat_des : "";
      a.value = categoryData && categoryData.category_id ? categoryData.category_id : "";
      setSelect({ ...a });
    }

    {
      const a =
        categoryData && categoryData.category && categoryData.category.length > 0
          ? categoryData.category.map((item) => item.category_id)
          : [];
      setCheckList(a);
    }
  }, [categoryToggle]);
  const data = props.dealProgress?.dealProgres?.data;
  const steps = data?.steps || {};

  const launchDeal = data?.steps["Launch Deal"];
  const active = data?.steps["Active"];
  const closing = data?.steps["Closing"];
  const result4 = data?.["result4"];
  const progressList = data?.progressList || [];

  const detail = props?.getListings?.detail?.detail || {};

  return (
    <div class="details-page-widget-block desktop-nav" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="page-widget-label text-capitalize">Deal Progress</div>
        </div>
        <div class="btn-block d-flex align-items-center"></div>
      </div>
      <div class="page-widget-body mt-3 ck-content">
        {props.loadingMain ? (
          <Skeleton height="250px" width="100%" />
        ) : (
          <div className=" deal-body ">
            <div className="d-flex justify-content-between">
              <div className="deal-column-body">
                <div class="page-widget-label text-capitalize deal-progress-type-heading">Launch Deal</div>
                <div className=" deal-column">
                  <div className="mb-1 deal-body-heading mt-2">Set Up</div>

                  {launchDeal &&
                    launchDeal["Set Up"] &&
                    launchDeal["Set Up"].length > 0 &&
                    launchDeal["Set Up"].map((item) =>
                      item.name === "Seller Listing Motivation / Time Frame" ? (
                        <div
                          className={`deal-box page-widget-label text-capitalize deal-box-text context-menu  ${
                            item.task_type > 0
                              ? item.status === "complete" || data?.attribute?.seller_motivation?.length > 0
                                ? "ytd"
                                : item.status === "pending"
                                ? "rtd"
                                : "ntd"
                              : progressList.includes(item.name)
                              ? "ytd"
                              : "ntd"
                          }`}
                        >
                          {item?.name}
                        </div>
                      ) : (
                        <div
                          className={`deal-box page-widget-label text-capitalize deal-box-text context-menu  ${
                            item.task_type > 0
                              ? item.status === "complete"
                                ? "ytd"
                                : item.status === "pending"
                                ? "rtd"
                                : "ntd"
                              : progressList.includes(item.name)
                              ? "ytd"
                              : "ntd"
                          }`}
                        >
                          {item?.name}
                        </div>
                      ),
                    )}
                  <div className="mb-1 deal-body-heading mt-2">Vital Information</div>

                  {launchDeal &&
                    launchDeal["Vital Information"] &&
                    launchDeal["Vital Information"].length > 0 &&
                    launchDeal["Vital Information"].map((item) =>
                      item.name === "Seller Listing Motivation / Time Frame" ? (
                        <div
                          className={`deal-box page-widget-label text-capitalize deal-box-text context-menu  ${
                            item.task_type > 0
                              ? item.status === "complete" || data?.attribute?.seller_motivation?.length > 0
                                ? "ytd"
                                : item.status === "pending"
                                ? "rtd"
                                : "ntd"
                              : progressList.includes(item.name)
                              ? "ytd"
                              : "ntd"
                          }`}
                        >
                          {item?.name}
                        </div>
                      ) : (
                        <div
                          className={`deal-box page-widget-label text-capitalize deal-box-text context-menu  ${
                            item.task_type > 0
                              ? item.status === "complete"
                                ? "ytd"
                                : item.status === "pending"
                                ? "rtd"
                                : "ntd"
                              : progressList.includes(item.name)
                              ? "ytd"
                              : "ntd"
                          }`}
                        >
                          {item?.name}
                        </div>
                      ),
                    )}
                  <div className="mb-1 deal-body-heading mt-2">Pricing, Motivation and Logistics</div>

                  {launchDeal &&
                    launchDeal["Pricing, Motivation and Logistics"] &&
                    launchDeal["Pricing, Motivation and Logistics"].length > 0 &&
                    launchDeal["Pricing, Motivation and Logistics"].map((item) =>
                      item.name === "Seller Listing Motivation / Time Frame" ? (
                        <div
                          className={`deal-box page-widget-label text-capitalize deal-box-text context-menu  ${
                            item.task_type > 0
                              ? item.status === "complete" || data?.attribute?.seller_motivation?.length > 0
                                ? "ytd"
                                : item.status === "pending"
                                ? "rtd"
                                : "ntd"
                              : progressList.includes(item.name)
                              ? "ytd"
                              : "ntd"
                          }`}
                        >
                          {item?.name}
                        </div>
                      ) : (
                        <div
                          className={`deal-box page-widget-label text-capitalize deal-box-text context-menu  ${
                            item.task_type > 0
                              ? item.status === "complete"
                                ? "ytd"
                                : item.status === "pending"
                                ? "rtd"
                                : "ntd"
                              : progressList.includes(item.name)
                              ? "ytd"
                              : "ntd"
                          }`}
                        >
                          {item?.name}
                        </div>
                      ),
                    )}
                  <div className="mb-1 deal-body-heading mt-2">Listing Admin</div>

                  {launchDeal &&
                    launchDeal["Listing Admin"] &&
                    launchDeal["Listing Admin"].length > 0 &&
                    launchDeal["Listing Admin"].map((item) =>
                      item.name === "Seller Listing Motivation / Time Frame" ? (
                        <div
                          className={`deal-box page-widget-label text-capitalize deal-box-text context-menu  ${
                            item.task_type > 0
                              ? item.status === "complete" || data?.attribute?.seller_motivation?.length > 0
                                ? "ytd"
                                : item.status === "pending"
                                ? "rtd"
                                : "ntd"
                              : progressList.includes(item.name)
                              ? "ytd"
                              : "ntd"
                          }`}
                        >
                          {item?.name}
                        </div>
                      ) : (
                        <div
                          className={`deal-box page-widget-label text-capitalize deal-box-text context-menu  ${
                            item.task_type > 0
                              ? item.status === "complete"
                                ? "ytd"
                                : item.status === "pending"
                                ? "rtd"
                                : "ntd"
                              : progressList.includes(item.name)
                              ? "ytd"
                              : "ntd"
                          }`}
                        >
                          {item?.name}
                        </div>
                      ),
                    )}

                  {data?.attribute?.category ? (
                    <div
                      className="mb-1 deal-body-heading mt-2 link-item "
                      onClick={() => props?.setShowConditionModal(!props?.showConditionModal)}
                    >
                      {data?.attribute?.category} Attributes
                    </div>
                  ) : (
                    ""
                  )}

                  {result4 &&
                    result4.length > 0 &&
                    result4.map((item) => (
                      <div
                        className={`deal-box page-widget-label text-capitalize deal-box-text context-menu  ${
                          item.task_type > 0
                            ? item.status === "complete"
                              ? "ytd"
                              : item.status === "pending"
                              ? "rtd"
                              : "ntd"
                            : progressList.includes(item.attribute)
                            ? "ytd"
                            : "ntd"
                        }`}
                      >
                        {item?.attribute}
                      </div>
                    ))}
                </div>
              </div>
              <div className="deal-column-body">
                <div class="page-widget-label text-capitalize deal-progress-type-heading">Active</div>
                <div className=" deal-column">
                  <div className="mb-1 deal-body-heading">Seller Effort</div>
                  {active &&
                    active["Seller Effort"] &&
                    active["Seller Effort"].length > 0 &&
                    active["Seller Effort"].map((step) => {
                      const array = data?.reserveStep?.split(" ") || [];
                      const reservePercentage = array.length > 0 ? array[array?.length - 1]?.slice(0, -1) : 0;
                      return step?.name === "Seller Motivation" ? (
                        <>
                          <div
                            className={`deal-box page-widget-label text-capitalize deal-box-text context-menu   ${
                              progressList.includes(step.name) || data?.attribute?.seller_motivation?.length > 0
                                ? "ytd"
                                : "ntd"
                            }`}
                          >
                            {step?.name}
                          </div>
                          <div
                            className={`deal-box ${
                              reservePercentage == "0"
                                ? "ntd"
                                : reservePercentage < 25
                                ? "ytd"
                                : reservePercentage > 50
                                ? "rtd"
                                : "yellowtd"
                            }`}
                          >
                            <div class="page-widget-label text-capitalize deal-box-text ">{data?.reserveStep}</div>
                          </div>
                        </>
                      ) : (
                        <div
                          className={`deal-box page-widget-label text-capitalize deal-box-text context-menu  ${
                            step.task_type > 0
                              ? step.status === "complete"
                                ? " ytd"
                                : step.status === "pending"
                                ? "rtd"
                                : "ntd"
                              : progressList.includes(step.name)
                              ? "ytd"
                              : "ntd"
                          }`}
                        >
                          {step?.name}
                        </div>
                      );
                    })}
                  <div className="mb-1 deal-body-heading mt-2">Buyer Interest</div>
                  {active &&
                    active["Buyer Interest"] &&
                    active["Buyer Interest"].length > 0 &&
                    active["Buyer Interest"].map((step) =>
                      step?.name === "Unanswered Buyer Questions" ? (
                        <div
                          className={`deal-box page-widget-label text-capitalize deal-box-text context-menu  ${
                            data?.questions?.length === 0
                              ? "ntd"
                              : data.questions.some((item) => item.qs_id === 1)
                              ? "rtd"
                              : "ytd"
                          }`}
                        >
                          {step?.name}
                        </div>
                      ) : (
                        <div
                          className={`deal-box page-widget-label text-capitalize deal-box-text context-menu  ${
                            step.task_type > 0
                              ? step.status === "complete"
                                ? " ytd"
                                : step.status === "pending"
                                ? "rtd"
                                : "ntd"
                              : progressList.includes(step.name)
                              ? "ytd"
                              : "ntd"
                          }`}
                        >
                          {step?.name}
                        </div>
                      ),
                    )}
                  <div className="mb-1 deal-body-heading mt-2">Sales Effort</div>
                  {active &&
                    active["Sales Effort"] &&
                    active["Sales Effort"].length > 0 &&
                    active["Sales Effort"].map((step) => (
                      <div
                        className={`deal-box page-widget-label text-capitalize deal-box-text context-menu  ${
                          step.task_type > 0
                            ? step.status === "complete"
                              ? " ytd"
                              : step.status === "pending"
                              ? "rtd"
                              : "ntd"
                            : progressList.includes(step.name)
                            ? "ytd"
                            : "ntd"
                        }`}
                      >
                        {step?.name}
                      </div>
                    ))}
                  {data?.neglected?.length > 0 && data?.neglected[0]?.neglected > 0 && (
                    <div
                      className={`deal-box page-widget-label text-capitalize deal-box-text context-menu neglected-deal`}
                    >
                      Neglected
                    </div>
                  )}
                </div>
              </div>
              <div className="deal-column-body">
                <div class="page-widget-label text-capitalize deal-progress-type-heading">Closing</div>
                <div className=" deal-column">
                  <div className="mb-1 deal-body-heading">Seller Acceptance</div>
                  {closing &&
                    closing["Seller Acceptance"] &&
                    closing["Seller Acceptance"].length > 0 &&
                    closing["Seller Acceptance"].map((step) =>
                      step.name === "Seller Accepted Bid" && progressList.includes(step.name) ? (
                        <div
                          className={`deal-box page-widget-label text-capitalize deal-box-text cursor-pointer  ${
                            step.name === "Work Out"
                              ? "work-out-td"
                              : step.task_type > 0
                              ? step.status === "complete"
                                ? "ytd"
                                : step.status === "pending"
                                ? "rtd"
                                : "ntd"
                              : progressList.includes(step.name)
                              ? "ytd cursor-pointer"
                              : "ntd"
                          }`}
                          onClick={() => {
                            if ("Seller Accepted Bid") {
                              props.refStart();
                              props?.updateDealProgress(
                                props?.auctionId,
                                {
                                  action: "toggle_auction_progress",
                                  step: "Seller Accepted Bid",
                                  category: "Closing",
                                  task_type: "0",
                                },
                                (res) => {
                                  if (res && res.status == 200) {
                                    props.getDealProgress(props?.auctionId, (res) => {
                                      props?.refStop();
                                      if (res.status === 200) {
                                      }
                                    });
                                  }
                                },
                              );
                            }
                          }}
                        >
                          {step.name === "Seller Accepted Bid" ? "Seller Accepted Bid " : ""}
                          {step.name === "Seller Accepted Bid" && data?.taskStatus?.["stamp_complete_date"]
                            ? data?.taskStatus["stamp_complete_date"]
                            : ""}
                        </div>
                      ) : (
                        <div
                          className={`deal-box page-widget-label text-capitalize deal-box-text context-menu ${
                            step.name === "Work Out"
                              ? "work-out-td"
                              : step.task_type > 0
                              ? step.status === "complete"
                                ? "ytd"
                                : step.status === "pending"
                                ? "rtd"
                                : "ntd"
                              : progressList.includes(step.name)
                              ? "ytd cursor-pointer"
                              : step.name === "Seller Accepted Bid"
                              ? "ntd cursor-pointer"
                              : "ntd"
                          }`}
                          onClick={() => {
                            if (step.name === "Seller Accepted Bid") {
                              props.refStart();
                              props?.updateDealProgress(
                                props?.auctionId,
                                {
                                  action: "toggle_auction_progress",
                                  step: "Seller Accepted Bid",
                                  category: "Closing",
                                  task_type: "0",
                                },
                                (res) => {
                                  if (res && res.status == 200) {
                                    props.getDealProgress(props?.auctionId, (res) => {
                                      props?.refStop();
                                      if (res.status === 200) {
                                      }
                                    });
                                  }
                                },
                              );
                            }
                          }}
                        >
                          {step.name === "Seller Accepted Bid" && progressList.includes(step.name)
                            ? step.name
                            : step.name}
                        </div>
                      ),
                    )}
                  <div className="mb-1 deal-body-heading mt-2">Sale Type</div>
                  {closing &&
                    closing["Sale Type"] &&
                    closing["Sale Type"].length > 0 &&
                    closing["Sale Type"].map((step) => (
                      <div
                        className={`deal-box page-widget-label text-capitalize deal-box-text context-menu  ${
                          step.name === "Work Out"
                            ? "work-out-td"
                            : step.task_type > 0
                            ? step.status === "complete"
                              ? "ytd"
                              : step.status === "pending"
                              ? "rtd"
                              : "ntd"
                            : progressList.includes(step.name)
                            ? "ytd"
                            : "ntd"
                        }`}
                      >
                        {step.name}
                      </div>
                    ))}
                  <div className="mb-1 deal-body-heading mt-2">Inspection & Logistics</div>
                  {closing &&
                    closing["Inspection & Logistics"] &&
                    closing["Inspection & Logistics"].length > 0 &&
                    closing["Inspection & Logistics"].map((step) => (
                      <div
                        className={`deal-box page-widget-label text-capitalize deal-box-text context-menu  ${
                          step.name === "Work Out"
                            ? "work-out-td"
                            : step.task_type > 0
                            ? step.status === "complete"
                              ? "ytd"
                              : step.status === "pending"
                              ? "rtd"
                              : "ntd"
                            : progressList.includes(step.name)
                            ? "ytd"
                            : "ntd"
                        }`}
                      >
                        {step.name}
                      </div>
                    ))}
                  <div className="mb-1 deal-body-heading mt-2">Seller Verifications</div>
                  {closing &&
                    closing["Seller Verifications"] &&
                    closing["Seller Verifications"].length > 0 &&
                    closing["Seller Verifications"].map((step) => (
                      <div
                        className={`deal-box page-widget-label text-capitalize deal-box-text context-menu  ${
                          step.name === "Work Out"
                            ? "work-out-td"
                            : step.task_type > 0
                            ? step.status === "complete"
                              ? "ytd"
                              : step.status === "pending"
                              ? "rtd"
                              : "ntd"
                            : progressList.includes(step.name)
                            ? step.name === "Visit Completed"
                              ? "cursor-pointer ytd"
                              : step.name === "Proof of Ownership"
                              ? "cursor-pointer ytd"
                              : step.name === "Proof of Quantity"
                              ? "cursor-pointer ytd"
                              : "ytd"
                            : step.name === "Visit Completed" ||
                              step.name === "Proof of Ownership" ||
                              step.name === "Proof of Quantity"
                            ? "ntd cursor-pointer"
                            : "ntd"
                        }`}
                        onClick={() => {
                          if (step.name === "Visit Completed") {
                            props.refStart();
                            props?.updateDealProgress(
                              props?.auctionId,
                              {
                                action: "toggle_auction_progress",
                                step: "Visit Completed",
                                category: "Closing",
                                task_type: "0",
                              },
                              (res) => {
                                if (res && res.status == 200) {
                                  props.getDealProgress(props?.auctionId, (res) => {
                                    props?.refStop();
                                    if (res.status === 200) {
                                    }
                                  });
                                }
                              },
                            );
                          } else if (step.name === "Proof of Ownership") {
                            props.refStart();
                            props?.updateDealProgress(
                              props?.auctionId,
                              {
                                action: "toggle_auction_progress",
                                step: "Proof of Ownership",
                                category: "Closing",
                                task_type: "0",
                              },
                              (res) => {
                                if (res && res.status == 200) {
                                  props.getDealProgress(props?.auctionId, (res) => {
                                    props?.refStop();
                                    if (res.status === 200) {
                                    }
                                  });
                                }
                              },
                            );
                          } else if ("Proof of Quantity") {
                            props.refStart();
                            props?.updateDealProgress(
                              props?.auctionId,
                              {
                                action: "toggle_auction_progress",
                                step: "Proof of Quantity",
                                category: "Closing",
                                task_type: "0",
                              },
                              (res) => {
                                if (res && res.status == 200) {
                                  props.getDealProgress(props?.auctionId, (res) => {
                                    props?.refStop();
                                    if (res.status === 200) {
                                    }
                                  });
                                }
                              },
                            );
                          }
                        }}
                      >
                        {step.name}
                      </div>
                    ))}
                  <div className="mb-1 deal-body-heading mt-2">Buyer Verifications</div>
                  {closing &&
                    closing["Buyer Verifications"] &&
                    closing["Buyer Verifications"].length > 0 &&
                    closing["Buyer Verifications"].map((step) => (
                      <div
                        className={`deal-box page-widget-label text-capitalize deal-box-text context-menu  ${
                          step.name === "Work Out"
                            ? "work-out-td"
                            : step.task_type > 0
                            ? step.status === "complete"
                              ? "ytd"
                              : step.status === "pending"
                              ? "rtd"
                              : "ntd"
                            : progressList.includes(step.name)
                            ? "ytd"
                            : "ntd"
                        }`}
                      >
                        {step.name}
                      </div>
                    ))}
                  <div className="mb-1 deal-body-heading mt-2">Buyer Transactional</div>
                  {closing &&
                    closing["Buyer Transactional"] &&
                    closing["Buyer Transactional"].length > 0 &&
                    closing["Buyer Transactional"].map((step) => (
                      <div
                        className={`deal-box page-widget-label text-capitalize deal-box-text context-menu  ${
                          step.name === "Work Out"
                            ? "work-out-td"
                            : step.task_type > 0
                            ? step.status === "complete"
                              ? "ytd"
                              : step.status === "pending"
                              ? "rtd"
                              : "ntd"
                            : progressList.includes(step.name)
                            ? `ytd ${session?.accessLevels?.accounting_payment && "cursor-pointer"}`
                            : `ntd ${session?.accessLevels?.accounting_payment && "cursor-pointer"}`
                        }`}
                        onClick={() => {
                          if (session?.accessLevels?.accounting_payment)
                            if (step.name === "Deposit Paid") {
                              props.refStart();
                              props?.updateDealProgress(
                                props?.auctionId,
                                {
                                  action: "toggle_auction_progress",
                                  step: "Deposit Paid",
                                  category: "Closing",
                                  task_type: "0",
                                },
                                (res) => {
                                  if (res && res.status == 200) {
                                    props.getDealProgress(props?.auctionId, (res) => {
                                      props?.refStop();
                                      if (res.status === 200) {
                                      }
                                    });
                                  }
                                },
                              );
                            } else if (step.name === "Buyer Paid") {
                              props.refStart();
                              props?.updateDealProgress(
                                props?.auctionId,
                                {
                                  action: "toggle_auction_progress",
                                  step: "Buyer Paid",
                                  category: "Closing",
                                  task_type: "0",
                                },
                                (res) => {
                                  if (res && res.status == 200) {
                                    props.getDealProgress(props?.auctionId, (res) => {
                                      props?.refStop();
                                      if (res.status === 200) {
                                      }
                                    });
                                  }
                                },
                              );
                            } else if (step.name === "Sold") {
                              props.refStart();
                              props?.updateDealProgress(
                                props?.auctionId,
                                {
                                  action: "toggle_auction_progress",
                                  step: "Sold",
                                  category: "Closing",
                                  task_type: "0",
                                },
                                (res) => {
                                  if (res && res.status == 200) {
                                    props.getDealProgress(props?.auctionId, (res) => {
                                      props?.refStop();
                                      if (res.status === 200) {
                                      }
                                    });
                                  }
                                },
                              );
                            }
                        }}
                      >
                        {step.name}
                      </div>
                    ))}
                  <div className="mb-1 deal-body-heading mt-2">Product Releases</div>
                  {closing &&
                    closing["Product Releases"] &&
                    closing["Product Releases"].length > 0 &&
                    closing["Product Releases"].map((step) =>
                      step?.name === "Picked Up" ? (
                        <div
                          className={`deal-box page-widget-label text-capitalize deal-box-text cursor-pointer  ${
                            progressList.includes("Picked Up") ? "ytd" : "ntd"
                          }`}
                          onClick={() => {
                            props.refStart();
                            props?.updateDealProgress(
                              props?.auctionId,
                              {
                                action: "toggle_auction_progress",
                                step: "Picked Up",
                                category: "Closing",
                                task_type: "0",
                              },
                              (res) => {
                                if (res && res.status == 200) {
                                  props.getDealProgress(props?.auctionId, (res) => {
                                    props?.refStop();
                                    if (res.status === 200) {
                                    }
                                  });
                                }
                              },
                            );
                          }}
                        >
                          {detail?.pickedup_date ? (
                            <>
                              <p style={{ marginBottom: "0px" }}>Picked Up</p>
                              <small>
                                {" "}
                                {moment(detail.pickedup_date).format("MM/DD/YY")}{" "}
                                {detail?.pickedup_by ? detail.pickedup_by : ""}
                              </small>
                            </>
                          ) : (
                            "Picked Up"
                          )}
                        </div>
                      ) : (
                        <div
                          className={`deal-box page-widget-label text-capitalize deal-box-text context-menu  ${
                            step.name === "Work Out"
                              ? "work-out-td"
                              : step.task_type > 0
                              ? step.status === "complete"
                                ? "ytd"
                                : step.status === "pending"
                                ? "rtd"
                                : "ntd"
                              : progressList.includes(step.name)
                              ? step.name === "Seller Paid"
                                ? "cursor-pointer ytd"
                                : step.name === "Release from Seller"
                                ? "cursor-pointer ytd"
                                : step.name === "Release to Buyer"
                                ? "cursor-pointer ytd"
                                : "ytd"
                              : step.name === "Seller Paid" && session?.accessLevels?.accounting_payment
                              ? "ntd cursor-pointer"
                              : step.name === "Release from Seller" || step.name === "Release to Buyer"
                              ? "ntd cursor-pointer"
                              : "ntd"
                          }`}
                          onClick={() => {
                            if (session?.accessLevels?.accounting_payment && step.name === "Seller Paid") {
                              props.refStart();
                              props?.updateDealProgress(
                                props?.auctionId,
                                {
                                  action: "toggle_auction_progress",
                                  step: "Seller Paid",
                                  category: "Closing",
                                  task_type: "0",
                                },
                                (res) => {
                                  if (res && res.status == 200) {
                                    props.getDealProgress(props?.auctionId, (res) => {
                                      if (res.status === 200) {
                                        props?.getPendingTasks(props?.auctionId, (res) => {
                                          if (res.status === 200) {
                                            if (res?.data?.data?.length > 0) {
                                              props?.getProductCount(props?.auctionId, (res) => {});
                                              props?.setShowPendingTask(true);
                                            } else {
                                              props?.setShowPendingTask(false);
                                            }
                                          }
                                        });
                                        props?.refStop();
                                      }
                                    });
                                  }
                                },
                              );
                            } else if (step.name === "Release from Seller") {
                              props.refStart();
                              props?.updateDealProgress(
                                props?.auctionId,
                                {
                                  action: "toggle_auction_progress",
                                  step: "Release from Seller",
                                  category: "Closing",
                                  task_type: "0",
                                },
                                (res) => {
                                  if (res && res.status == 200) {
                                    props.getDealProgress(props?.auctionId, (res) => {
                                      props?.refStop();
                                      if (res.status === 200) {
                                      }
                                    });
                                  }
                                },
                              );
                            } else if (step.name === "Release to Buyer") {
                              props.refStart();
                              props?.updateDealProgress(
                                props?.auctionId,
                                {
                                  action: "toggle_auction_progress",
                                  step: "Release to Buyer",
                                  category: "Closing",
                                  task_type: "0",
                                },
                                (res) => {
                                  if (res && res.status == 200) {
                                    props.getDealProgress(props?.auctionId, (res) => {
                                      props?.refStop();
                                      if (res.status === 200) {
                                      }
                                    });
                                  }
                                },
                              );
                            }
                          }}
                        >
                          {step.name === "Release From Seller" ? "1212121212" : step.name}
                        </div>
                      ),
                    )}
                  <div className="mb-1 deal-body-heading mt-2">Post Sale</div>
                  {closing &&
                    closing["Post Sale"] &&
                    closing["Post Sale"].length > 0 &&
                    closing["Post Sale"].map((step) => (
                      <div
                        className={`deal-box page-widget-label text-capitalize deal-box-text context-menu  ${
                          step.task_type > 0
                            ? step.status === "complete"
                              ? "ytd"
                              : step.status === "pending"
                              ? "rtd"
                              : "ntd"
                            : progressList.includes(step.name)
                            ? step.name === "Work Out"
                              ? "work-out-td cursor-pointer ytd"
                              : step.name === "Buyer Requested a Refund"
                              ? "cursor-pointer ytd"
                              : step.name === "Commission Paid" && session?.accessLevels?.accounting_payment
                              ? "cursor-pointer ytd"
                              : "ytd"
                            : step.name === "Buyer Requested a Refund"
                            ? "ntd cursor-pointer"
                            : step.name === "Commission Paid" && session?.accessLevels?.accounting_payment
                            ? "ntd cursor-pointer"
                            : "ntd"
                        }`}
                        onClick={() => {
                          if (step.name === "Work Out") {
                            props.refStart();
                            props?.updateDealProgress(
                              props?.auctionId,
                              {
                                action: "toggle_auction_progress",
                                step: "Work Out",
                                category: "Closing",
                                task_type: "0",
                              },
                              (res) => {
                                if (res && res.status == 200) {
                                  props.getDealProgress(props?.auctionId, (res) => {
                                    props?.refStop();
                                    if (res.status === 200) {
                                    }
                                  });
                                }
                              },
                            );
                          } else if (step.name === "Buyer Requested a Refund") {
                            props.refStart();
                            props?.updateDealProgress(
                              props?.auctionId,
                              {
                                action: "toggle_auction_progress",
                                step: "Buyer Requested a Refund",
                                category: "Closing",
                                task_type: "0",
                              },
                              (res) => {
                                if (res && res.status == 200) {
                                  props.getDealProgress(props?.auctionId, (res) => {
                                    props?.refStop();
                                    if (res.status === 200) {
                                    }
                                  });
                                }
                              },
                            );
                          } else if (step.name === "Commission Paid" && session?.accessLevels?.accounting_payment) {
                            props.refStart();
                            props?.updateDealProgress(
                              props?.auctionId,
                              {
                                action: "toggle_auction_progress",
                                step: "Commission Paid",
                                category: "Closing",
                                task_type: "0",
                              },
                              (res) => {
                                if (res && res.status == 200) {
                                  props.getDealProgress(props?.auctionId, (res) => {
                                    props?.refStop();
                                    if (res.status === 200) {
                                    }
                                  });
                                }
                              },
                            );
                          }
                        }}
                      >
                        {step.name}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        )}
        <CategoryPreferencesModal
          show={categoryToggle}
          onHide={toggleCategoryPreferenaces}
          loadingCategory={loadingCategory}
          categoryDropdown={categoryDropDownData}
          categoryDropDownData={categoryDropDownData}
          setCategoryDropdown={setCategoryDropdown}
          checkList={checkList}
          setCheckList={setCheckList}
          select={select}
          setSelect={setSelect}
          subCat={subCat}
          setSubCat={setSubCat}
          handleSubmit={handleSubmit}
          categoryData={categoryData}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dealProgress: getMisc(state),
  getListings: getListings(state),
  conditionModal: getModals(state)?.conditionModal,
});
const mapDispatchToProps = (dispatch) => ({
  getCategoryPreferenaces: (params, callback) => dispatch(getCategoryPreferenaces(params, callback)),
  updateCategoryPreference: (params, data, callback) => dispatch(updateCategoryPreference(params, data, callback)),
  setShowConditionModal: (data) => dispatch(setConditionModal(data)),
  updateDealProgress: (params, data, callback) => dispatch(updateDealProgress(params, data, callback)),
  getDealProgress: (id, callback) => dispatch(getDealProgress(id, callback)),
  getPendingTasks: (id, callback) => dispatch(getPendingTasks(id, callback)),
  getProductCount: (id, callback) => dispatch(getProductCount(id, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(DealProgress));
