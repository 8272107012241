import React from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import DateFormat from "../../../common/DateFormat/dateFormat";
import NextButton from "../../../common/form/nextButton";

const AddBidTermsModal = ({ setBidTerm, bidTerm, ...props }) => {
  return (
    <Modal
      className="modal fade custom-modal"
      id="AddBidTermsModal"
      show={props.show}
      onHide={() => props.onHide()}
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
    >
      <div class="modal-content d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Bid Terms</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body class="custom-modal-body flex-grow-1 w-100 overflow-hidden">
          <div class="full-width-textarea-row d-flex flex-wrap">
            <div class="fwtr-input-box flex-grow-1">
              <textarea
                class="form-control w-100 h-40"
                id="FormControlTextareaL"
                value={bidTerm?.bidTerm}
                onChange={(e) => {
                  const val = { ...bidTerm };
                  val.bidTerm = e.target.value;
                  setBidTerm(val);
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            Cancel
          </button>
          <NextButton
            classData="btn btn-default"
            handleSubmit={() => props.handleSubmit()}
            label={"Save"}
            loading={props.loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddBidTermsModal;
