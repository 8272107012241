import React, { useEffect, useState } from "react";
import { Accordion, Modal } from "react-bootstrap";
import close from "../../include/images/close.svg";
import Editor from "../../common/editor/editor";
import NextButton from "../../common/form/nextButton";
import Select from "react-select";
import { customStyles } from "../../common/customStyles/reactSelectStyle";

const ProductConditionModal = ({ primaryCategory, setPrimaryCategory, ...props }) => {
  const categoryId =
    props.categoryDropdown && props.categoryDropdown.length > 0 && props.categoryDropdown.map((val) => val);

  const [value, setValues] = useState();
  useEffect(() => {
    if (props.select && props.select.value) {
      props.subCat &&
        props.subCat.length > 0 &&
        props.subCat.map((val) => {
          if (val.value === props.select.value) {
            setValues(val.subCategories);
          }
        }, []);
    }
  });
  useEffect(() => {
    props.setEditorLoaded(true);
  }, []);

  return (
    <Modal
      className="modal fade custom-modal"
      id="editCondition"
      dialogClassName="modal-dialog modal-dialog-centered modal-xxl"
      show={props.show}
      onHide={() => props.onHide()}
    >
      <div class="modal-content d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Edit Category, Condition & Manufacturer </h5>
          <button type="button" class="close-btn d-flex align-items-center justify-content-center">
            <img src={close} onClick={() => props.onHide()} />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 w-100 overflow-hidden" style={{ minHeight: "425px" }}>
          <div class="rdr-item d-flex fle x-nowrap mb-4" style={{ marginTop: "10px" }}>
            <div class="rdr-label-box align-self-start">Select a Category</div>
            <div class="rdr-form-box w-350">
              <Select
                menuPosition="fixed"
                menuShouldScrollIntoView
                value={
                  primaryCategory?.firstLevel?.active
                    ? props.select
                    : primaryCategory?.firstLevel?.name
                    ? { name: primaryCategory?.firstLevel?.name, value: primaryCategory?.firstLevel?.value }
                    : ""
                }
                styles={customStyles}
                className="basic-single "
                classNamePrefix="select-search  "
                placeholder={`Please Select`}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.value}
                options={props.categoryDropdown}
                name={"PrimayCategoryFirstLevel"}
                onChange={(event) => {
                  const data = { ...categoryId };
                  const filterData = props?.categoryDropDownData?.filter((ui) => ui?.category_id == event?.value);
                  if (filterData?.[0]?.subCategories?.length > 0) {
                    setPrimaryCategory({
                      firstLevel: {
                        name: filterData[0]?.description,
                        value: filterData[0]?.category_id,
                        subCategories: filterData[0]?.subCategories,
                      },
                    });
                    if (props?.select) {
                      props?.setSelect("");
                    }
                  } else {
                    data.value = event;
                    props.setSelect(data.value);
                    setPrimaryCategory({ firstLevel: { active: true } });
                  }
                }}
              />
              {props?.errors?.firstLevel ? <p style={{ color: "red" }}>{props?.errors?.firstLevel} </p> : ""}
            </div>
          </div>
          {primaryCategory?.firstLevel?.subCategories?.length > 0 ? (
            <div class="rdr-item d-flex fle x-nowrap mb-4" style={{ marginTop: "30px" }}>
              <div class="rdr-label-box align-self-start">Category Level 2</div>
              <div class="rdr-form-box  w-350">
                <Select
                  menuPosition="fixed"
                  menuShouldScrollIntoView
                  styles={customStyles}
                  value={
                    primaryCategory?.secondLevel?.active
                      ? { description: props?.select?.name, category_id: props?.select?.value }
                      : primaryCategory?.secondLevel?.name
                      ? {
                          description: primaryCategory?.secondLevel?.name,
                          category_id: primaryCategory?.secondLevel?.value,
                        }
                      : ""
                  }
                  className="basic-single  "
                  classNamePrefix="select-search "
                  placeholder={`Please Select`}
                  getOptionLabel={(option) => option.description}
                  getOptionValue={(option) => option.category_id}
                  options={primaryCategory?.firstLevel?.subCategories}
                  name={"PrimayCategorySecondLevel"}
                  onChange={(event) => {
                    const data = { ...categoryId };
                    const filterData = primaryCategory?.firstLevel?.subCategories?.filter(
                      (ui) => ui?.category_id == event?.category_id,
                    );
                    if (filterData?.[0]?.subCategories?.length > 0) {
                      setPrimaryCategory({
                        firstLevel: { ...primaryCategory?.firstLevel },
                        secondLevel: {
                          name: filterData[0]?.description,
                          value: filterData[0]?.category_id,
                          subCategories: filterData[0]?.subCategories,
                        },
                      });
                      if (props?.select) {
                        props?.setSelect("");
                      }
                    } else {
                      data.value = event;
                      props.setSelect({ name: event.description, value: event.category_id });
                      setPrimaryCategory({
                        firstLevel: { ...primaryCategory?.firstLevel },
                        secondLevel: { active: true },
                      });
                    }
                  }}
                />
                {props?.errors?.secondLevel ? <p style={{ color: "red" }}>{props?.errors?.secondLevel} </p> : ""}
              </div>
            </div>
          ) : (
            ""
          )}
          {primaryCategory?.secondLevel?.subCategories?.length > 0 ? (
            <div class="rdr-item d-flex fle x-nowrap mb-4" style={{ marginTop: "30px" }}>
              <div class="rdr-label-box align-self-start">Category Level 3</div>
              <div class="rdr-form-box  w-350">
                <Select
                  menuPosition="fixed"
                  menuShouldScrollIntoView
                  value={
                    primaryCategory?.thirdLevel?.active
                      ? { description: props?.select?.name, category_id: props?.select?.value }
                      : ""
                  }
                  className="basic-single  "
                  classNamePrefix="select-search  "
                  placeholder={`Please Select`}
                  styles={customStyles}
                  getOptionLabel={(option) => option.description}
                  getOptionValue={(option) => option.category_id}
                  options={primaryCategory?.secondLevel?.subCategories}
                  name={"PrimayCategoryThirdLevel"}
                  onChange={(event) => {
                    const data = { ...categoryId };

                    data.value = event;
                    props.setSelect({ name: event.description, value: event.category_id });
                    setPrimaryCategory({ ...primaryCategory, thirdLevel: { active: true } });
                  }}
                />
                {props?.errors?.thirdLevel ? <p style={{ color: "red" }}>{props?.errors?.thirdLevel} </p> : ""}
              </div>
            </div>
          ) : (
            ""
          )}
          {primaryCategory?.secondLevel?.active ? (
            props?.select?.name === "Aircraft Parts" || props?.select?.name === "Complete Aircrafts" ? (
              <div class="rdr-item d-flex fle x-nowrap mb-4" style={{ marginTop: "30px" }}>
                <div class="rdr-label-box align-self-start">Aviation Model</div>
                <div class="rdr-form-box  w-350">
                  <Select
                    menuPosition="fixed"
                    menuShouldScrollIntoView
                    value={props?.aviationType}
                    className="basic-single "
                    classNamePrefix="select-search"
                    placeholder={`Please Select`}
                    styles={customStyles}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.value}
                    options={[
                      { name: "Please Select", value: "0" },
                      ...props?.aviationModel?.data?.map((ds) => ({
                        name: ds?.description,
                        value: ds?.aviationModelID,
                      })),
                    ]}
                    name={"PrimayCategorySecondLeve"}
                    onChange={(e) => props?.setAviationType(e)}
                  />
                </div>
              </div>
            ) : (
              ""
            )
          ) : primaryCategory?.secondLevel?.name ? (
            primaryCategory?.secondLevel?.name === "Aircraft Parts" ||
            primaryCategory?.secondLevel?.name === "Complete Aircrafts" ? (
              <div class="rdr-item d-flex fle x-nowrap mb-4" style={{ marginTop: "30px" }}>
                <div class="rdr-label-box align-self-start">Aviation Model</div>
                <div class="rdr-form-box  w-350">
                  <Select
                    menuPosition="fixed"
                    menuShouldScrollIntoView
                    value={props?.aviationType}
                    className="basic-single "
                    classNamePrefix="select-search"
                    placeholder={`Please Select`}
                    styles={customStyles}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.value}
                    options={[
                      { name: "Please Select", value: "0" },
                      ...props?.aviationModel?.data?.map((ds) => ({
                        name: ds?.description,
                        value: ds?.aviationModelID,
                      })),
                    ]}
                    name={"PrimayCategorySecondLeve"}
                    onChange={(e) => props?.setAviationType(e)}
                  />
                </div>
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          <div class="rdr-item d-flex fle x-nowrap mb-4" style={{ marginTop: "10px" }}>
            <div class="rdr-label-box align-self-start">Manufacturer</div>
            <div class="rdr-form-box w-350">
              <Select
                menuPosition="fixed"
                menuShouldScrollIntoView
                value={props?.mfrValue?.manufacturer}
                className="basic-single "
                classNamePrefix="select-search  "
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.value}
                isClearable={true}
                isSearchable={true}
                name="manufacturer"
                isLoading={props?.loadingManufacturer}
                loadingMessage={() => "Loading..."}
                options={props?.options || []}
                placeholder={"Select Manufacturer"}
                styles={customStyles}
                onChange={(a) => {
                  const data = { ...value };
                  data.manufacturer = a;
                  props?.setMfrValue(data);
                }}
              />
            </div>
          </div>
          <div class="rdr-item d-flex fle x-nowrap mb-4" style={{ marginTop: "10px" }}>
            <div class="rdr-label-box align-self-start">Condition</div>
            <div class="rdr-form-box w-350">
              <Select
                // menuPortalTarget={document.body}
                menuPosition="fixed"
                menuShouldScrollIntoView
                value={props?.condition}
                className="basic-single  "
                classNamePrefix="select-search "
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.value}
                name={"conditionModal"}
                options={props.conditionOptions}
                placeholder="Select Condition"
                styles={customStyles}
                onChange={(a) => {
                  let data = { ...props.condition };
                  data = a;
                  props.setCondition(data);
                }}
              />
            </div>
          </div>
          {props?.condition?.value == "Others" && (
            <div class="d-flex flex-wrap mb-4 ">
              <div class="fwtr-label-box pt-2"></div>
              <div
                class="fwtr-input-box flex-grow-1 w-350 
           "
              >
                <input
                  name="otherTextArea"
                  className="form-control"
                  placeholder="Enter Condition Here"
                  value={props.otherData}
                  onChange={(e) => props.setOtherData(e.target.value)}
                />
              </div>
            </div>
          )}

          <div class="full-width-textarea-row d-flex flex-wrap mb-4">
            <div class="fwtr-label-box pt-2">Description</div>
            <div class="fwtr-input-box flex-grow-1">
              <Editor
                id="textarea"
                name="description"
                className="ck-content"
                onChange={(data) => {
                  props.setEditorData(data);
                }}
                editorLoaded={props.editorLoaded}
                value={props.savedData}
              />
            </div>
          </div>
          <table class="table table-borderless align-middle table-left-align mb-4">
            <colgroup>
              <col style={{ width: "15%" }} />
              <col style={{ width: "8%" }} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <td>Scrap</td>
                <td>
                  <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="scrap"
                      id="scrap1"
                      checked={props.scrapValue === 0 ? true : false}
                      onClick={() => props.setScrapValue(0)}
                    />
                    <label class="form-check-label" for="scrap1">
                      No
                    </label>
                  </div>
                </td>
                <td colspan="2">
                  <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="scrap"
                      id="scrap2"
                      checked={props.scrapValue === 1 ? true : false}
                      onClick={() => props.setScrapValue(1)}
                    />
                    <label class="form-check-label" for="scrap2">
                      Yes
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="d-flex align-items-center justify-content-center ysp mb-4" style={{ height: "30px" }}>
            <span>You can select upto 5 push categories</span>
          </div>
          <div class="row-details-row hide-scrollbar" style={{ height: "400px", overflowY: "scroll" }}>
            <div class="rdr-item d-flex flex-nowrap">
              <div class="rdr-label-box align-self-start">Push Categories</div>
              <Accordion className="accordion left-arrow-accordion small-checkbox">
                {Array.isArray(props.categoryDropDownData) &&
                  props.categoryDropDownData?.map((val, i) =>
                    val.subCategories?.length > 0 ? (
                      <Accordion.Item className="accordion-item" eventKey={i}>
                        <Accordion.Header className="accordion-header">{`${val.description}(${val.auctions})`}</Accordion.Header>
                        <Accordion.Body className="accordion-body">
                          {val?.subCategories?.map((data, i) =>
                            data?.subCategories?.length > 0 ? (
                              <Accordion className="accordion left-arrow-accordion small-checkbox">
                                <Accordion.Item className="accordion-item" eventKey={"200"}>
                                  <Accordion.Header className="accordion-header">{data.description}</Accordion.Header>
                                  <Accordion.Body className="accordion-body">
                                    {data?.subCategories?.map((er) => (
                                      <div class="form-check d-flex">
                                        <input
                                          id={`sub${er.category_id}`}
                                          name={er.category_id}
                                          class="form-check-input"
                                          type="checkbox"
                                          style={{
                                            height: "1.5rem",
                                            width: "1.5rem",
                                            cursor: "pointer",
                                          }}
                                          value={er.category_id}
                                          checked={props.checkList && props.checkList.includes(er.category_id)}
                                          onChange={() => {
                                            const checkedData = [...props.checkList];
                                            if (checkedData.includes(er.category_id)) {
                                              const a =
                                                checkedData &&
                                                checkedData.length &&
                                                checkedData.filter((item) => item !== er.category_id);
                                              props.setCheckList(a);
                                            } else {
                                              checkedData.push(er.category_id);
                                              props.setCheckList(checkedData);
                                            }
                                          }}
                                        />
                                        <label
                                          class="form-check-label fw-medium "
                                          for={`sub${er.category_id}`}
                                          style={{ paddingLeft: "15px" }}
                                        >
                                          {`${er.description} ${er.auctions ? `(${er.auctions})` : ""}`}
                                        </label>
                                      </div>
                                    ))}
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            ) : (
                              <div class="form-check d-flex">
                                <input
                                  id={`vdv${data.category_id}`}
                                  name={data.category_id}
                                  class="form-check-input"
                                  type="checkbox"
                                  style={{
                                    height: "1.5rem",
                                    width: "1.5rem",
                                    cursor: "pointer",
                                  }}
                                  value={data.category_id}
                                  checked={props.checkList && props.checkList.includes(data.category_id)}
                                  onChange={() => {
                                    const checkedData = [...props.checkList];
                                    if (checkedData.includes(data.category_id)) {
                                      const a =
                                        checkedData &&
                                        checkedData.length &&
                                        checkedData.filter((item) => item !== data.category_id);
                                      props.setCheckList(a);
                                    } else {
                                      checkedData.push(data.category_id);
                                      props.setCheckList(checkedData);
                                    }
                                  }}
                                />
                                <label
                                  class="form-check-label fw-medium "
                                  for={`vdv${data.category_id}`}
                                  style={{ paddingLeft: "15px" }}
                                >
                                  {`${data.description} ${data.auctions ? `(${data.auctions})` : ""}`}
                                </label>
                              </div>
                            ),
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    ) : (
                      <div class="form-check d-flex">
                        <input
                          id={`subcat${val.category_id}`}
                          name={val.category_id}
                          class="form-check-input"
                          type="checkbox"
                          style={{
                            height: "1.5rem",
                            width: "1.5rem",
                            cursor: "pointer",
                          }}
                          value={val.category_id}
                          checked={props.checkList && props.checkList.includes(val.category_id)}
                          onChange={() => {
                            const checkedData = [...props.checkList];
                            if (checkedData.includes(val.category_id)) {
                              const a =
                                checkedData &&
                                checkedData.length &&
                                checkedData.filter((item) => item !== val.category_id);
                              props.setCheckList(a);
                            } else {
                              checkedData.push(val.category_id);
                              props.setCheckList(checkedData);
                            }
                          }}
                        />
                        <label
                          class="form-check-label fw-medium "
                          for={`subcat${val.category_id}`}
                          style={{ paddingLeft: "15px", marginTop: "5px" }}
                        >
                          {`${val.description} ${val.auctions ? `(${val.auctions})` : ""}`}
                        </label>
                      </div>
                    ),
                  )}
              </Accordion>
            </div>
          </div>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div className="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2 global-cancel-button"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            Cancel
          </button>
          {props?.checkList?.length > 5 ? (
            <button className="btn btn-default btn-disabled">Save</button>
          ) : (
            <NextButton
              label="Save"
              classData="btn btn-default"
              handleSubmit={props.handleSubmit}
              loading={props.loadingCategory}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(ProductConditionModal);
