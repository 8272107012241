import React, { useState } from "react";
import {
  getInvoice,
  getInvoiceList,
  inspectionWaiver,
  invoiceAddNote,
  updateInvoiceStatus,
} from "../../../store/invoices";
import { connect } from "react-redux";
import DateFormat from "../../../common/DateFormat/dateFormat";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import DeActivateRateModal from "../../../layouts/modals/invoicesModal/deActivateRateModal";
import CollectModal from "../../../layouts/modals/invoicesModal/collectModal";
import { updateCollect } from "../../../store/invoices";
import MarkPaidModal from "../../../layouts/modals/invoicesModal/markPaidModal";
import { getFeedback } from "../../../store/invoices";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import ResendModal from "../../../layouts/modals/invoicesModal/resendModal";
import ReactivateModal from "../../../layouts/modals/invoicesModal/reactivateModal";
import DeactivateModal from "../../../layouts/modals/invoicesModal/deactivateModal";
import helpCircle from "../../../include/images/help-circle.svg";

import greenEmail from "../../../include/images/green-mail.svg";
import notVerified from "../../../include/images/mail-not-verified.svg";
import invalid from "../../../include/images/mail-invalid.svg";
import spamEmail from "../../../include/images/mail-spammed-blocked.svg";
import purpleEmail from "../../../include/images/mail-purple.svg";

import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import AddNotesModal from "../../../layouts/modals/invoicesModal/addNotesModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import More from "../../../include/images/more-icon.svg";
import AuthorizationPayModal from "../../../layouts/modals/invoicesModal/authorizationPayModal";
import { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { getBids } from "../../../store/bids";

const NewInvoices = (props) => {
  const val = localStorage.getItem("data");
  const session = JSON.parse(val);
  const addInvoice = props?.invoices?.addInvoice?.data;
  const count = props?.count?.invoice;
  const [showDeactivateRateModal, setShowDeactivateRateModal] = useState(false);
  const [showMarkPaidModal, setShowMarkPaidModal] = useState(false);
  const [collectData, setCollectData] = useState({ send_collection_notice: 0 });
  const [showCollectModal, setShowCollectModal] = useState(false);
  const [showResendModal, setShowResendModal] = useState(false);
  const [noticeDate, setNoticeDate] = useState("");
  const [showReactivateModal, setShowReactivateModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showAuthorizationModal, setShowAuthorizationModal] = useState(false);
  const [inspectionRes, setInspectionRes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [Params, setParams] = useState({
    invoice_id: 0,
    action: "",
  });
  const [statusData, setStatusData] = useState({
    invoice_id: 0,
    action: "",

    posted_by: 0,
    member_id: 0,
    buyer_id: 0,
    rating: 0,
    review: "",
  });
  const [inspectionWaiver, setInspectionWaiver] = useState({
    inv_id: 0,
    member_id: 0,
    buyer_id: 0,
  });
  const [invoiceNote, setInvoiceNote] = useState({
    invoice_id: 0,
    invoiceNotePrompt: false,
    invoiceNote: "",
  });
  const [statusStaticData, setStatusStaticData] = useState({});

  const [showAddNote, setShowAddNote] = useState(false);
  const invoiceData = props && props.invoices && props.invoices.invoices && props.invoices.invoices.data;
  const userAccess = props?.invoices?.userAccess?.data;

  const listFindNoCase = (list, value) => {
    const listArray = list.split(",").map((item) => item.trim().toLowerCase());
    if (value) {
      const lowercasedValue = value && value.trim().toLowerCase();
      const index = listArray.indexOf(lowercasedValue);
      return index !== -1 ? index + 1 : 0;
    } else {
      return 0;
    }
  };

  const allowedJobRoles = "Management,Accounting,Legal";

  const toggleAuthorizationModal = () => {
    setShowAuthorizationModal(!showAuthorizationModal);
  };

  const toggleDeactivateModal = () => {
    setShowDeactivateRateModal(!showDeactivateRateModal);
  };
  const toggleMarkPaidModal = () => {
    setShowMarkPaidModal(!showMarkPaidModal);
  };

  const toggleCollectModal = () => {
    setShowCollectModal(!showCollectModal);
  };
  const toggleResendModal = () => {
    setShowResendModal(!showResendModal);
  };
  const toggelReactivateModal = () => {
    setShowReactivateModal(!showReactivateModal);
  };
  const toggelDeactivatedModal = () => {
    setShowDeactivateModal(!showDeactivateModal);
  };
  const toggleAddNote = () => {
    setShowAddNote(!showAddNote);
  };

  const updateStatus = (id, datas) => {
    setLoading(true);
    props.refStart();
    props.updateInvoiceStatus(id, datas, (res) => {
      if (res.status === 200) {
        props.getInvoiceList(props.auctionId, (res) => {
          if (res.status === 200) {
            props.getBids(props.auctionId, (res) => {});
            setLoading(false);
            props.refStop();
            if (datas.action === "resend") {
              toggleResendModal();
              toast(<AlertSuccess message={"Information Saved"} />);
            } else if (datas.action === "reactivate") {
              toggelReactivateModal();
              toast(<AlertSuccess message={"Information Saved"} />);
            } else if (datas.action === "deactivate") {
              toggelDeactivatedModal();
              toast(<AlertSuccess message={"Information Saved"} />);
            }
          } else {
            setLoading(false);
            props.refStop();
            if (datas.action === "resend") {
              toggleResendModal();
              toast(<AlertSuccess message={"Information Saved"} />);
            } else if (datas.action === "reactivate") {
              toggelReactivateModal();
              toast(<AlertSuccess message={"Information Saved"} />);
            } else if (datas.action === "deactivate") {
              toggelDeactivatedModal();
              toast(<AlertSuccess message={"Information Saved"} />);
            }
          }
        });
      } else {
        setLoading(false);
        props.refStop();
        toast(<AlertError message={"Something Went Wrong"} />);
      }
    });
  };

  const collectUpdate = () => {
    setLoading(true);
    props.refStart();
    props.updateCollect(props.auctionId, collectData, (res) => {
      if (res.status === 200) {
        props.getInvoiceList(props.auctionId, (res) => {
          if (res.status === 200) {
            props.getBids(props.auctionId, (res) => {});
            setLoading(false);
            props.refStop();
            toggleCollectModal();
          } else {
            setLoading(false);
            props.refStop();
            toggleCollectModal();
          }
        });
      } else {
        setLoading(false);
        props.refStop();
        toast(<AlertError message={"Something Went Wrong"} />);
      }
    });
  };

  const openMarkPaidModal = (invoiceId, action) => {
    props.refStart();
    props.getFeedback(props.auctionId, invoiceId, action, (res) => {
      if (res.status === 200) {
        const value = res.data.data;
        setStatusData({
          invoice_id: invoiceId ? invoiceId : 0,
          action: action ? action : "",

          posted_by: value.posted_by ? value.posted_by : 0,
          member_id: value.member_id ? value.member_id : 0,
          buyer_id: value.buyer_id ? value.buyer_id : 0,
          rating: value.rating ? value.rating : 0,
          review: value.review ? value.review : "",
        });
        setStatusStaticData(value);
        props.refStop();
        toggleMarkPaidModal();
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const openDeactivateRateModal = (invoiceId, action) => {
    props.refStart();
    props.getFeedback(props.auctionId, invoiceId, action, (res) => {
      if (res.status === 200) {
        const value = res.data.data;
        setStatusData({
          invoice_id: invoiceId ? invoiceId : 0,
          action: action ? action : "",

          posted_by: value.posted_by ? value.posted_by : 0,
          member_id: value.member_id ? value.member_id : 0,
          buyer_id: value.buyer_id ? value.buyer_id : 0,
          rating: value.rating ? value.rating : 0,
          review: value.review ? value.review : "",
        });
        setStatusStaticData(value);
        props.refStop();
        toggleDeactivateModal();
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const invoiceStatusUpdate = () => {
    setLoading(true);
    props.refStart();
    props.updateInvoiceStatus(props.auctionId, statusData, (res) => {
      if (res.status === 200) {
        props.getInvoiceList(props.auctionId, (res) => {
          if (res.status === 200) {
            props.getBids(props.auctionId, (res) => {});
            setLoading(false);
            props.refStop();
            if (statusData.action === "markpaid") {
              toggleMarkPaidModal();
              toast(<AlertSuccess message={"Information Saved"} />);
            } else if (statusData.action === "deactivate") {
              toggleDeactivateModal();
              toast(<AlertSuccess message={"Information Saved"} />);
            }
          } else {
            setLoading(false);
            props.refStop();
            if (statusData.action === "markpaid") {
              toggleMarkPaidModal();
              toast(<AlertSuccess message={"Information Saved"} />);
            } else if (statusData.action === "deactivate") {
              toggleDeactivateModal();
              toast(<AlertSuccess message={"Information Saved"} />);
            }
          }
        });
      } else {
        setLoading(false);
        props.refStop();
        toast(<AlertError message={"Something Went Wrong"} />);
      }
    });
  };

  //Handle Inspection Waiver
  const handleInspectionWaiver = () => {
    setLoading(true);
    props.refStart();
    props.inspectionWaiver(props.auctionId, inspectionWaiver, (res) => {
      if (res.status === 200) {
        props.refStop();
        setLoading(false);
        toggleAuthorizationModal();
        props.getInvoiceList(props.auctionId, (res) => {});
        props.getBids(props.auctionId, (res) => {});
        setInspectionRes(true);
      } else {
        props.refStop();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Add Note
  const handleAddNote = () => {
    setLoading(true);
    props.refStart();
    props.invoiceAddNote(props.auctionId, invoiceNote, (res) => {
      if (res.status === 200) {
        const detail = res?.data?.data ? res?.data?.data : {};
        const data = { ...invoiceNote };
        data.invoiceNotePrompt = false;
        data.invoiceNote = detail.invoice_notes ? detail.invoice_notes : "";
        setInvoiceNote(data);
        props.refStop();
        setLoading(false);
        toggleAddNote();
      } else {
        props.refStop();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  useEffect(() => {
    if (invoiceNote.invoiceNotePrompt) {
      handleAddNote();
    }
  }, [invoiceNote]);

  const activeInvoice = invoiceData?.filter((du) => du?.active);

  return (
    <div class="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center">
            <div
              class="page-widget-label text-capitalize cursor-pointer"
              onClick={() => {
                props?.setSection("invoiceRef");
                props?.scrollToView("invoiceRef");
              }}
            >
              {" "}
              Invoices {!props?.loadingMain && (count > 0 ? `(${count})` : "(0)")}
            </div>
          </div>
          <li
            className="badge-item cursor-pointer"
            onClick={() => {
              props?.setSection("invoiceRef");
              props?.scrollToView("invoiceRef");
            }}
            style={{ marginLeft: "10px" }}
          >
            <div className="badge-box" style={{ background: "#DADEE0" }}>
              Click here to view all
            </div>
          </li>
        </div>
      </div>
      <div class={activeInvoice?.length > 0 && "page-widget-body mt-3"}>
        {activeInvoice?.length > 0 && (
          <div class="table-responsive table-custom ">
            <table class="table mb-0 align-middle table-bordered  horizontal-table top-bids-table ">
              <colgroup>
                <col style={{ width: "5%" }} />
                <col style={{ width: "30%" }} />
                <col style={{ width: "30%" }} />
                <col style={{ width: "17.5%" }} />
                <col style={{ width: "17.5%" }} />
              </colgroup>
              <thead>
                <tr>
                  <td></td>
                  <td>Invoice</td>
                  <td>Date</td>
                  <td>Status</td>
                  <td>Amount</td>
                </tr>
              </thead>
              <tbody>
                {activeInvoice
                  ?.slice(-5)
                  ?.reverse()
                  ?.map((val) => (
                    <tr
                      style={{
                        backgroundColor:
                          val.active && !val?.paid ? "white" : val.paid ? "darkseagreen" : !val?.active ? "pink" : "",
                      }}
                    >
                      <td>
                        {" "}
                        <div class="td-action-btn-group d-flex align-items-center justify-content-between  position-absolute">
                          <div class="dropdown">
                            <a
                              class="td-a-icon dropdown-toggle"
                              style={{ margin: "-8px" }}
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src={More} alt="" />
                            </a>
                            <ul class="dropdown-menu ms-1 position-relative">
                              {val?.active ? (
                                <>
                                  <li className="menu-item">
                                    <a
                                      className="dropdown-item customized-drpdown-btn"
                                      href="javascript:void(0)"
                                      onClick={() => {
                                        const data = { ...invoiceNote };
                                        data.invoice_id = val.id;
                                        data.invoiceNotePrompt = true;
                                        data.invoiceNote = "";
                                        setInvoiceNote(data);
                                      }}
                                    >
                                      Invoice Notes
                                    </a>
                                  </li>
                                  <li className="menu-item">
                                    <a
                                      class="dropdown-item customized-drpdown-btn"
                                      href="javascript:void(0)"
                                      onClick={() => {
                                        if (val.active && val.paid) {
                                          updateStatus(props.auctionId, {
                                            invoice_id: val.id,
                                            action: "markunpaid",
                                          });
                                        } else if (val.active && !val.paid) {
                                          openMarkPaidModal(val.id, "markpaid");
                                        }
                                      }}
                                    >
                                      {val.paid ? "Mark Unpaid" : "Mark Paid"}
                                    </a>
                                  </li>
                                  <li className="menu-item">
                                    <a className="dropdown-item customized-drpdown-btn" href="javascript:void(0)">
                                      {val.active &&
                                        (val.inspection_waiver_btn ? (
                                          val.btn_text && val?.btn_text?.split("")?.length > 0 ? (
                                            <p>{val.btn_text}</p>
                                          ) : (
                                            <p>
                                              {`Authorization to Pay Seller Form Submitted at ${moment(
                                                val.wavier_time,
                                              ).format("MM/DD/YY")}`}{" "}
                                              <br /> {val.waived_by}
                                            </p>
                                          )
                                        ) : val?.paid &&
                                          props.sellerVerificationStatus === "green" &&
                                          props.payableCount > 0 &&
                                          props.qtyConfirmed &&
                                          props.locationFilled ? (
                                          inspectionRes ? (
                                            <p>
                                              {`Authorization to Pay Seller Form Submitted at ${moment().format(
                                                "MM/DD/YY",
                                              )}`}
                                              <br /> {session.name01}
                                            </p>
                                          ) : (
                                            <span
                                              // className="btn btn-primary btn-xs-size btn-auto"
                                              onClick={() => {
                                                const data = { ...inspectionWaiver };
                                                data.inv_id = val.id;
                                                data.buyer_id = val.buyer_id;
                                                data.member_id = val.member_id;
                                                setInspectionWaiver(data);
                                                toggleAuthorizationModal();
                                              }}
                                            >
                                              Authorization to Pay Seller
                                            </span>
                                          )
                                        ) : (
                                          <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                              <Tooltip style={{ position: "fixed", padding: "25px" }}>
                                                <span>
                                                  You have not met requirements to use this tool. Requirements to send
                                                  this form include the following:
                                                  <br />
                                                  1). Invoice must be marked 'paid'
                                                  <br />
                                                  2). Product location must have a complete address
                                                  <br />
                                                  3). Seller verification must be marked GREEN
                                                  <br />
                                                  4). A payable must be set up
                                                  <br />
                                                  5). Quantity must be confirmed and checked GREEN
                                                </span>
                                              </Tooltip>
                                            }
                                          >
                                            <span
                                              style={{
                                                cursor: "not-allowed",
                                                opacity: "0.4",
                                                color: "##137c10",
                                              }}
                                            >
                                              Authorization to Pay Seller
                                            </span>
                                          </OverlayTrigger>
                                        ))}
                                    </a>
                                  </li>
                                  <li className="menu-item">
                                    <a
                                      className="dropdown-item customized-drpdown-btn"
                                      href="javascript:void(0)"
                                      onClick={() => {
                                        if (val.refund) {
                                          updateStatus(props.auctionId, {
                                            invoice_id: val.id,
                                            action: "refunded",
                                          });
                                        } else {
                                          updateStatus(props.auctionId, {
                                            invoice_id: val.id,
                                            action: "markrefund",
                                          });
                                        }
                                      }}
                                    >
                                      {val.refund ? "Refunded" : "Refund"}
                                    </a>
                                  </li>
                                  <li className="menu-item">
                                    <a
                                      className="dropdown-item customized-drpdown-btn"
                                      href="javascript:void(0)"
                                      onClick={() => {
                                        setParams({
                                          invoice_id: val.id,
                                          action: "resend",
                                        });
                                        toggleResendModal();
                                      }}
                                    >
                                      {" "}
                                      {"Resend"}
                                    </a>
                                  </li>
                                  <li className="menu-item">
                                    <a
                                      className="dropdown-item customized-drpdown-btn"
                                      href="javascript:void(0)"
                                      onClick={() => {
                                        setCollectData({
                                          send_collection_notice: val.id,
                                        });
                                        setNoticeDate(val.notice_date);
                                        toggleCollectModal();
                                      }}
                                    >
                                      Collect
                                    </a>
                                  </li>
                                  <li className="menu-item">
                                    <a
                                      className="dropdown-item customized-drpdown-btn"
                                      href="javascript:void(0)"
                                      onClick={() => openDeactivateRateModal(val.id, "deactivate")}
                                    >
                                      Deactivate and Rate
                                    </a>
                                  </li>{" "}
                                </>
                              ) : (
                                ""
                              )}
                              <li className="menu-item">
                                <a
                                  className="dropdown-item customized-drpdown-btn"
                                  href="javascript:void(0)"
                                  onClick={() => {
                                    if (val?.active) {
                                      setParams({
                                        invoice_id: val.id,
                                        action: "deactivate",
                                      });
                                      toggelDeactivatedModal();
                                    } else {
                                      setParams({
                                        invoice_id: val.id,
                                        action: "reactivate",
                                      });
                                      toggelReactivateModal();
                                    }
                                  }}
                                >
                                  {val.active ? "Deactivate" : "Reactivate"}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className="d-block">
                          {" "}
                          <Link
                            to={`/invoice/view-invoice?auctionId=${props.auctionId}&id=${val.id}&date=${val.date_created}`}
                            state={{ a: "dk" }}
                            target="_blank"
                          >
                            {val.inv_num ? val.inv_num : "N/A"}
                          </Link>
                        </span>
                        <a
                          className="cursor-pointer"
                          onClick={() => {
                            if (val?.name) {
                              if (process.env.REACT_APP_MODE == "development") {
                                window.open(
                                  `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val.user_id}`,
                                );
                              } else {
                                window.open(`https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val.user_id}`);
                              }
                            }
                          }}
                        >
                          {val?.name ? val?.name : "N/A"}
                        </a>
                        <span
                          className="d-block"
                          onClick={() => {
                            if (val?.company_name) {
                              if (process.env.REACT_APP_MODE == "development") {
                                window.open(
                                  `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val.user_id}`,
                                );
                              } else {
                                window.open(`https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val.user_id}`);
                              }
                            }
                          }}
                        >
                          {val.company_name ? val.company_name : "N/A"}
                        </span>
                        <span className="d-block">
                          {val.email ? (
                            <>
                              <a className="green-text" href={`mailto:${val.email}`}>
                                {val.email}
                              </a>
                              <div class="d-text-item">
                                {val?.emailstatuscode === 0 ? (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip style={{ position: "fixed" }}>
                                        <span>Email OK</span>
                                      </Tooltip>
                                    }
                                  >
                                    <img src={greenEmail} alt="" />
                                  </OverlayTrigger>
                                ) : val?.emailstatuscode === 1 ? (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip style={{ position: "fixed" }}>
                                        <span>
                                          Email Temporarily Suspended/Unreachable or Undetermined (Soft Bounce)
                                        </span>
                                      </Tooltip>
                                    }
                                  >
                                    <img src={purpleEmail} alt="" />
                                  </OverlayTrigger>
                                ) : val?.emailstatuscode === 2 ? (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip style={{ position: "fixed" }}>
                                        <span>Email Invalid</span>
                                      </Tooltip>
                                    }
                                  >
                                    <img src={invalid} alt="" />
                                  </OverlayTrigger>
                                ) : val?.emailstatuscode === 3 ? (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip style={{ position: "fixed" }}>
                                        <span>Email Blocked by Spam or Firewall</span>
                                      </Tooltip>
                                    }
                                  >
                                    <img src={spamEmail} alt="" />
                                  </OverlayTrigger>
                                ) : (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip style={{ position: "fixed" }}>
                                        <span>Email Not Verified</span>
                                      </Tooltip>
                                    }
                                  >
                                    <img src={notVerified} alt="" />
                                  </OverlayTrigger>
                                )}
                              </div>
                            </>
                          ) : (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip style={{ position: "fixed" }}>
                                  <span>Email Not Verified</span>
                                </Tooltip>
                              }
                            >
                              <img src={notVerified} alt="" />
                            </OverlayTrigger>
                          )}
                        </span>
                        <span className="d-block">
                          {val.city || val.abb ? `${val.city} ${val?.abb ? ", " + val.abb : ""} ` : "N/A"}
                        </span>
                      </td>
                      <td>
                        <span className="d-block">
                          {" "}
                          <span className="fw-500"> Created </span> :{" "}
                          {val.date_created ? <DateFormat date={val.date_created} /> : "N/A"}
                        </span>
                        <span className="d-block">
                          <span className="fw-500"> Last Sent </span> :{" "}
                          {val.last_sent ? <DateFormat date={val.last_sent} /> : "N/A"}
                        </span>
                      </td>
                      <td>
                        <span className="d-block">
                          {" "}
                          <span className="fw-500"> Deposit </span> : {val.deposit ? "Yes" : "No"}
                        </span>
                        <span className="d-block">
                          {" "}
                          <span className="fw-500"> Notice </span> : {val.notice ? "Yes" : "No"}
                        </span>
                        <span className="d-block">
                          {" "}
                          <span className="fw-500"> Viewed </span> : {val.viewed ? val.viewed : "N/A"}
                        </span>
                        <span className="d-block">
                          {" "}
                          <span className="fw-500"> Paid </span> : {val.paid ? "Yes" : "No"}
                        </span>
                      </td>
                      <td>
                        <span
                          className="d-block cursor-pointer"
                          data-for={`inv-num-${val?.id}`}
                          data-tip={
                            val?.inspection_deposit
                              ? `
                        <span>Total Amount: ${
                          val?.total_amount
                            ? "$" +
                              new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                              }).format(val?.total_amount)
                            : "$0.00"
                        }</span> <br>
                        <span>(Credit): ${
                          val?.credit
                            ? "$" +
                              new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                              }).format(val?.credit)
                            : "$0.00"
                        }</span> <br>
                        <span>Deposit Due Now: ${
                          val?.inspection_deposit
                            ? "$" +
                              new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                              }).format(val?.inspection_deposit)
                            : "$0.00"
                        }</span> <br>
                        <span>Deposit Due Later: ${
                          val?.amount
                            ? "$" +
                              new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                              }).format(val?.amount)
                            : "$0.00"
                        }</span> <br>
                        `
                              : `
                              <span>Total Amount: ${
                                val?.total_amount
                                  ? "$" +
                                    new Intl.NumberFormat("en-US", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                    }).format(val?.total_amount)
                                  : "$0.00"
                              }</span> <br>
                        <span>(Credit): ${
                          val?.credit
                            ? "$" +
                              new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                              }).format(val?.credit)
                            : "$0.00"
                        }</span> <br>
                        <span>Sales Tax: ${
                          val?.sales_tax
                            ? "$" +
                              new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                              }).format(val?.sales_tax)
                            : "$0.00"
                        }</span> <br>
                        <span>Total Due: ${
                          val?.amount
                            ? "$" +
                              new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                              }).format(val?.amount)
                            : "$0.00"
                        }</span>
                        `
                          }
                        >
                          {val.amount
                            ? "$" +
                              new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                              }).format(val?.amount)
                            : "$0.00"}
                        </span>
                        <ReactTooltip
                          effect="solid"
                          place="top"
                          type="dark"
                          html={true}
                          border={true}
                          id={`inv-num-${val?.id}`}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <AddNotesModal
        show={showAddNote}
        onHide={toggleAddNote}
        addInvoice={addInvoice}
        loading={loading}
        setInvoiceNote={setInvoiceNote}
        invoiceNote={invoiceNote}
        handleSubmit={handleAddNote}
      />
      <CollectModal
        show={showCollectModal}
        onHide={toggleCollectModal}
        loadingDelete={loading}
        date={noticeDate}
        submitHandler={collectUpdate}
      />
      <AuthorizationPayModal
        show={showAuthorizationModal}
        onHide={toggleAuthorizationModal}
        loadingDelete={loading}
        submitHandler={handleInspectionWaiver}
      />
      <ResendModal
        show={showResendModal}
        onHide={toggleResendModal}
        loadingDelete={loading}
        submitHandler={updateStatus}
        id={props.auctionId}
        data={Params}
      />
      <ReactivateModal
        show={showReactivateModal}
        onHide={toggelReactivateModal}
        loadingDelete={loading}
        submitHandler={updateStatus}
        id={props.auctionId}
        data={Params}
      />
      <DeactivateModal
        show={showDeactivateModal}
        onHide={toggelDeactivatedModal}
        loadingDelete={loading}
        submitHandler={updateStatus}
        id={props.auctionId}
        data={Params}
      />

      <MarkPaidModal
        show={showMarkPaidModal}
        onHide={toggleMarkPaidModal}
        statusData={statusData}
        setStatusData={setStatusData}
        data={statusStaticData}
        handleSubmit={invoiceStatusUpdate}
        loading={loading}
      />

      <DeActivateRateModal
        show={showDeactivateRateModal}
        onHide={toggleDeactivateModal}
        statusData={statusData}
        setStatusData={setStatusData}
        data={statusStaticData}
        handleSubmit={invoiceStatusUpdate}
        loading={loading}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getInvoiceList: (params, callback) => dispatch(getInvoiceList(params, callback)),
  getbids: (params, callback) => dispatch(getBids(params, callback)),
  updateInvoiceStatus: (params, data, callback) => dispatch(updateInvoiceStatus(params, data, callback)),
  updateCollect: (params, data, callback) => dispatch(updateCollect(params, data, callback)),
  inspectionWaiver: (params, data, callback) => dispatch(inspectionWaiver(params, data, callback)),
  getFeedback: (params, invoiceId, action, callback) => dispatch(getFeedback(params, invoiceId, action, callback)),
  invoiceAddNote: (params, data, callback) => dispatch(invoiceAddNote(params, data, callback)),
});

const mapStateToProps = (state) => ({
  invoices: getInvoice(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(NewInvoices));
